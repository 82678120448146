@import "../../../../../../sass/variable.scss";

#back-button {
    padding: 8px 16px;
    background-color: $vertFonce;
    color: white;
    transition: 0.3s;
    border-radius: 6px;

    &:hover {
        background-color: white;
        color: $vertFonce;
    }
}