.map-batiment-menu {
    position: fixed;
    top: 85px;
    right: 10px;
    width: 300px;
    height: fit-content;
    max-height: 400px;
    background-color: white;
    z-index: 5;
    border-radius: 10px;

    .chakra-accordion__item {
        border-bottom-width: 0px !important;
        border-top-width: 0px !important;

        h2 {
            background-color: transparent;

            &::before,
            &::after,
            * {
                border: none;
                border-color: transparent;
            }

            button {
                border-radius: 10px;
                text-align: center;
                margin: 0;
                padding: 10px;
                background-color: #225522;
                color: white;

                &:hover {
                    background-color: white;
                    color: #225522;
                }
            }
        }

        .panel {
            height: fit-content;
            max-height: 360px;
            overflow-y: scroll;
            overflow-x: hidden;
            padding: 0px;
        }
    }
}
