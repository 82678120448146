.dashboard-title {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;

    h1 {
        font-size: 1.5rem;
        font-weight: 700;
        color: var(--color-primary);
    }

    button {
        right: 50px;
        position: fixed;
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        background-color: #225522;
        color: white;
        transition: 0.3s;

        :hover {
            background-color: white;
            color: #225522;
        }
    }
}
