@import "../../../../../../sass/variable.scss";

#chakra-modal-sub-counter-drawer {
    #header {
        background-color: $vertFonce;
        color: white;
        text-align: center;
    }

    .chakra-modal__close-btn {
        color: white;
    }

    #body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-top: 30px;

        #main-sensor {
            margin-left: 10%;
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            gap: 3px;

            &:hover {
                text-decoration: underline;
                transition: 0.3s ease-in-out;
            }
        }

        #sub-counter-list {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            margin-left: 30%;
            gap: 10px;

            button {
                display: flex;
                align-items: center;
                gap: 3px;

                &:hover {
                    text-decoration: underline;
                    transition: 0.3s ease-in-out;
                }
            }
        }
    }
}
