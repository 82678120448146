.myBatiment {
    main {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding-top: 75px;
        padding-left: 75px;
        height: 100vh;
        width: 100%;
        gap: 20px;

        @media screen and (max-width: 910px) {
            padding-left: 0;
            
        }

        #first-line {
            display: flex;
            gap: 20px;
            justify-content: center;
            margin-top: 20px;
            flex-wrap: wrap;
            width: 100%;

            @media screen and (max-width: 768px) {
                justify-content: center;
                align-items: center;
                flex-direction: column;
            }
        }

        #second-line {
            display: flex;
            gap: 20px;
            flex-wrap: wrap;
            width: 100%;
            justify-content: center;
        }
    }
}
