@import "../../sass/variable.scss";

.error-boundary {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100vh;

    img {
        width: 20vw;

        @media screen and (max-width: 768px) {
            width: 50vw;
            margin-bottom: 50px;
        }
    }

    section {
        display: flex;

        @media screen and (max-width: 1100px) {
            flex-direction: column-reverse;
            align-items: center;
        }

        .text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: left;
            gap: 25px;

            h1 {
                text-align: left;
                font-family: "Manrope", sans-serif;
                font-size: 5rem;
                font-weight: bold;
                font-style: oblique;
                color: $bleuFonce;
                width: 100%;

                @media screen and (max-width: 1100px) {
                    font-size: 3.5rem;
                    text-align: center;
                }
            }

            h2 {
                text-align: start;
                font-family: "Manrope", sans-serif;
                font-size: 2rem;
                font-weight: 300;
                color: $grisClair;

                @media screen and (max-width: 1100px) {
                    font-size: 1.5rem;
                    text-align: center;
                }
            }

            p {
                text-align: left;
                width: 100%;
                font-family: "Manrope", sans-serif;
                font-size: 1.5rem;
                font-weight: 500;
                color: $grisClair;

                @media screen and (max-width: 1100px) {
                    text-align: center;
                }
            }

            ul {
                list-style: none;
                text-align: start;
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 10px;

                @media screen and (max-width: 1100px) {
                    text-align: center;
                    margin-bottom: 15vh;
                }

                a {
                    color: $bleuFonce;
                    font-family: "Manrope", sans-serif;
                    font-size: 1.1rem;
                    font-weight: 500;
                    text-decoration: none;
                    transition: color 0.3s;

                    &:hover {
                        color: $bleuClair;
                    }
                }
            }
        }

        .error-img {
            width: 35vw;
            pointer-events: none;

            @media screen and (max-width: 768px) {
                width: 65vw;
            }
        }
    }
}
