@import "../../sass/variable.scss";

.passwordInput {
    display: flex;
    width: 100%;

    input {
        border-radius: 10px 0 0 10px !important;
    }
    
    button {
        border: none;
        border-radius: 0 10px 10px 0;
        background-color: $blanc;
    }
}