#chakra-modal-dahsboard-drawer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    header {
        width: 100%;
        background-color: #225522;
        color: white;
        text-align: center;
    }

    button {
        color: white;
        top: 15px;
    }

    .stack {
        margin-top: 35px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 25px;

        select {

            option {
                word-wrap: break-word;
            }
        }

        .calendar {
            border: 2px solid #225522;
            border-radius: 6px;
        }
    }
}
