@import "../../../../../../../../sass/variable.scss";

.addManualMeasure {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    flex-wrap: wrap;

    @media screen and (max-width: 700px){
        flex-direction: column;
    }

    &>div {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        gap: 5px;

        @media screen and (max-width: 700px){
            flex-direction: column;
        }

        &>select {
            background-color: transparent;
    
            border: 1px solid $vertFonce;
            border-radius: 5px;
            padding: 3px;
        }
    }

    input[type="submit"] {
        background-color: $vertFonce;
        color: white;
        border: none;
        padding: 8px 16px;
        border-radius: 5px;
        cursor: pointer;
        transition: 0.3s ease-out;

        &:hover {
            background-color: $blanc;

            color: $vertFonce;
        }
    }

    input[type="number"] {
        background-color: transparent;
        border: 1px solid $vertFonce;
        border-radius: 5px;
        padding: 3px;
    }
}
