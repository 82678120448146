$vertClair: #578830;
$vertFonce: #225522;
$bleuFonce: #117166;
$bleuClair: #8CB7AC;
$gris: #272727;
$grisClair: #454445;
$grisClair2: #8e8f8e;
$blanc: #eee;

@font-face {
    font-family: "Manrope";
    src: url("../assets/polices/Manrope/Manrope-VariableFont_wght.ttf");
};