.dashboard-page {
    main {
        padding-left: 75px;
        padding-top: 75px;

        @media screen and (max-width: 910px) {
            padding-left: 0;
        }
    }
}
