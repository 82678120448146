@import "../../../../sass/variable.scss";

.dashboard-batiment {

    height: 100%;

    main {
        margin: 75px 0 0 75px;
        height: 100%;

        @media screen and (max-width: 910px) {
            margin: 75px 0 0 0;
            
        }

        #nav {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;
            width: 100%;
            height: fit-content;
            padding-top: 20px;
            padding-bottom: 20px;

            .btn-next {
                border: 2px solid $vertFonce;
                padding: 6px 14px;
                border-radius: 6px;
                transition: all 0.3s;

                &:hover {
                    background-color: $vertFonce;
                    color: white;
                }
            }

            .btn-prev {
                background-color: $vertFonce;
                padding: 8px 16px;
                border-radius: 6px;
                transition: all 0.3s;
                color: white;

                &:hover {
                    background-color: white;
                    color: $vertFonce;
                }
            }
        }

        .dashboard {
            display: grid;
            grid-template-columns: repeat(6, 15%);
            grid-template-rows: repeat(7, 1fr);
            height: calc(100% - 95px); // Add a semicolon at the end of the line
            width: 100%;
            gap: 10px;

            .panel-3d {
                grid-column: 1 / 5;
                grid-row: 4 / 8;

                @media screen and (max-width: 910px) {
                    grid-column: 1 / 7;
                    
                }
            }

            #no-3d {
                grid-column: 1 / 5;
                grid-row: 4 / 8;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                @media screen and (max-width: 910px) {
                    grid-column: 1 / 7;   
                }

                img {
                    width: 25%;
                }
                
                h2 {
                    font-size: 1.5em;
                    font-weight: 600;
                    color: $vertFonce;
                }

                p {
                    font-size: 1em;
                    font-weight: 400;
                    color: $grisClair2;
                    text-align: center;

                    a {
                        color: $vertFonce;
                        text-decoration: underline;
                        font-weight: 600;
                    }
                }
            }

            .map-panel {
                grid-column: 5 / 7;
                grid-row: 4 / 8;

                @media screen and (max-width: 910px) {
                    display: none;
                }
            }

            .dashboard-batiment-chart-panel {
                grid-row: 1 / 4;
                grid-column: 1 / 7;
                width: 100%;
                height: 100%;
            }
        }
    }
}