.panel-3d {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;

    .switch-mode {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: absolute;
        z-index: 10;
    }
}