@import "../../sass/variable.scss";

.logoutButton {
    color: white;
    padding: 10px 15px;
    background-color: $vertFonce;
    border-radius: 6px;

    &:hover {
        background-color: white;
        color: $vertFonce;
        transition: 0.3s ease-in-out;
    }
}