@import "../../sass/variable.scss";

#chakra-toast-manager-top-right {
    margin-top: 12vh;
}

.login-page {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;

    .main {
        background-image: url("../../assets/img/accueil/background.svg");
        background-repeat: no-repeat;
        background-position: center;
        height: 100vh;

        .login-modal {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
            width: fit-content;
            margin: 5vh auto 11vh 10vw;
            padding: 35px;
            border-radius: 10px;
            font-family: "Manrope", sans-serif;
            background-color: white;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
            gap: 10vh;

            @media screen and (max-width: 910px) {
                border: none;
                margin: 5vh auto 5vh auto;
            }

            h1 {
                color: $bleuFonce;
                font-size: 2.5em;
                text-align: center;
                font-weight: bold;
            }

            form {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 5vh;
                width: 25vw;

                @media screen and (max-width: 1448px) {
                    width: 100%;
                }

                article {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 5px;

                    width: 100%;

                    input {
                        width: 100%;
                        border-radius: 10px;
                        height: 50px;
                        border: none;
                        background-color: $blanc;
                        outline: none;
                        font-family: "Manrope", sans-serif;
                        font-size: 1em;
                        padding-right: 0;
                    }

                    label {
                        margin-bottom: 5px;
                        font-size: 1.25em;
                        font-weight: 500;
                        color: $grisClair;
                    }
                }

                .register {
                    p {
                        color: $grisClair;
                    }

                    a {
                        color: $bleuFonce;
                    }
                }

                input[type="submit"] {
                    background-color: $bleuFonce;
                    border-radius: 10px;
                    padding: 15px;
                    width: 100%;
                    border: none;
                    color: $blanc;
                    font-size: 1.25em;
                    font-family: "Manrope", sans-serif;
                    cursor: pointer;

                    &:hover {
                        background-color: $blanc;
                        color: $bleuFonce;
                        transition: 0.3s;
                    }
                }
            }
        }
    }
}
