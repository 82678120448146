@import "../../sass/variable.scss";

.footer-component {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $bleuFonce;
    color: white;

    div {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5vh 0;

        @media screen and (max-width: 768px) {
            flex-direction: column;
            padding: 2vh 0;
        }

        div {
            display: flex;
            text-align: center;
            justify-content: center;
            align-items: flex-start;

            @media screen and (max-width: 768px) {
                flex-direction: column;
            }

            h1 {
                font-size: 3rem;
                margin: 10px 0;
                font-weight: bold;
                text-align: start;
                width: fit-content;
                padding: 5vw;
                font-family: 'Manrope', sans-serif;

                @media screen and (max-width: 768px) {
                    text-align: center;
                    font-size: 2em;
                }
            }

            div {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                gap: 15px;
                
                p {
                    font-size: 1.1em;
                    font-family: 'Manrope', sans-serif;

                    @media screen and (max-width: 768px){
                        font-size: 0.9em;
                    }
                }

                a {
                    text-decoration: underline;
                    font-size: 1.1em;
                    margin: 10px;
                    font-family: 'Manrope', sans-serif;

                    @media screen and (max-width: 768px){
                        font-size: 0.9em;
                    }
                }

                h2 {
                    font-weight: bold;
                    font-size: 1.2em;
                    text-align: center;
                    font-family: 'Manrope', sans-serif;

                    @media screen and (max-width: 768px){
                        font-size: 1em;
                    }
                }
            }
        }
    }

    hr {
        width: calc(100% - 2px);
        border: 1px solid #34877c;
    }
    p {
        font-size: 1em;
        margin: 10px 0;
        text-align: center;
    }
}
