@import "@sass/variable.scss";

.map-batiment-menu-item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 5px;
    gap: 5px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
        background-color: #d4d4d4;
    }

    p {
        text-align: center;
    }

    a {
        text-decoration: none;
        color: white;
        background-color: $vertFonce;
        padding: 5px 15px;
        border-radius: 5px;
        font-family: 'Manrope', sans-serif;
        transition: all 0.3s ease-in-out;

        &:hover {
            background-color: white;
            color: $vertFonce;
        }
    }
}