@import "../../sass/variable.scss";

.navBarLogged {
    display: flex;
    flex-direction: column;
    position: relative;
    position: fixed;
    height: 100%;
    background-color: $gris;
    z-index: 100;
    top: 0;

    @media screen and (max-width: 910px) {
        display: none;
    }

    .sidebar {
        background-color: $gris;
        color: #fff;
        height: 100%;
        width: 300px;

        .top_section {
            display: flex;
            align-items: center;
            padding: 20px 15px;
            
            .logo {
                width: 65%;
                cursor: pointer;
            }

            .bars {
                font-size: 40px;
                display: flex;
                margin-left: 30px;
                cursor: pointer;

                &:hover {
                    color: $vertFonce;
                    transition: 0.3s ease-in-out;
                }
            }
        }

        .link {
            display: flex;
            padding: 10px 15px;
            gap: 15px;
            justify-content: center;

            &:hover {
                background-color: #fff;
                color: $gris;
                transition: 0.3s ease-in-out;
            }

            .icon,
            .link_text {
                font-size: 20px;
            }
        }

        .active {
            background-color: $vertFonce;
            color: #fff;
        }
    }

    .bottomSection {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 20px;

        svg {
            color: white;
            &:hover {
                color: $vertFonce;
                transition: 0.3s;
            }
        }
    }
}

.navbar-mobile {
    display: none;

    @media screen and (max-width: 910px) {
        display: flex;

        button {
            position: fixed;
            top: 15px;
            left: 10px;
            z-index: 101;
            background-color: transparent;

            &:hover {
                background-color: transparent;
            }
        }
    }
}

.drawer-logged-navbar-mobile {
    background-color: $gris !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 25px;

    .close-button {
            position: absolute;
            top: 5px;
            right: 5px;
            z-index: 101;
            background-color: transparent;
            color: #fff;

            &:hover {
                background-color: transparent;
            }
        }

    .drawer-body-logged-navbar-mobile {
        gap: 25px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        a {
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 25px;
            width: 100%;

            &:hover {
                    color: $vertFonce;
                    transition: 0.3s;
                }

            .icon {
                font-size: 25px;
            }

            .link_text {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                width: 90%;
            }
        }
    }

    .drawer-footer-logged-navbar-mobile {
        background-color: $gris;
    }
}
