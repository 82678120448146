@import "../../../../../../sass/variable.scss";

.MySiteAccordion {
    width: 70vw;
    display: flex;
    flex-direction: column;
    gap: 2vh;
    padding: 15px;
    box-shadow: 0px 0px 15px $gris;
    border-radius: 10px;
    overflow-y: scroll;
    scrollbar-width: none;

    @media screen and (max-width: 600px) {
        width: 100%;
        margin-left: 0;
        box-shadow: 0 0 0 $gris;
        padding: 0;
    }

    h2 {
        margin-right: auto;
        margin-left: auto;
        font-family: "Manrope", sans-serif;
    }

    .lienSimple {
        border-radius: 10px;

        &:hover {
            background-color: #fff;
        }


        a {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 15px;

            div {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                margin: 15px 0;
                gap: 5px;
                background-color: rgba($color: #000000, $alpha: 0);

                p {
                    font-family: "Manrope", sans-serif;
                }

                .css-r6z5ec {
                    width: fit-content !important;
                }
            }

        }
    }

    div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        border: none;
        background-color: $blanc;
        border-radius: 10px;

        h2 {
            width: 100%;

            button {
                padding: 15px;

                span {
                    display: flex;
                    justify-content: space-between;

                    div {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: flex-start;
                        gap: 5px;
                        width: fit-content;
                        background-color: rgba($color: #000000, $alpha: 0);

                        p {
                            font-family: "Manrope", sans-serif;
                        }
                    }
                }
            }
        }

        .chakra-collapse {
            width: 100%;

            div {
                padding: 15px 0 0 0;

                ul {
                    width: 100%;

                    li {
                        transition: 0.3s;
                        list-style: none;
                        border-radius: 0;

                        &:hover {
                            background-color: #fff;
                        }

                        a {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            padding: 15px 15px 15px 0;

                            p {
                                padding-left: 15px;
                            }

                            div {
                                display: flex;
                                flex-direction: row;
                                padding: 0 0 0 40px;
                                background-color: rgba(
                                    $color: #000000,
                                    $alpha: 0
                                );

                                p {
                                    font-family: "Manrope", sans-serif;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
