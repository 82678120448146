@import "../../../../../../../../sass/variable.scss";

#chakra-modal-room-drawer {
    .chakra-modal__body {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
    }

    .chakra-modal__header {
        background-color: $vertFonce;

        h1 {
            text-align: center;
            color: white;
        }
    }
}
