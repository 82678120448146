#calibration {
    main {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 75px;
        padding-left: 75px;
        height: 100vh;
        overflow-y: scroll;
        
        @media screen and (max-width: 910px) {
            padding-left: 0;
        }
    }

}
