@import "../../sass/variable.scss";
@import "atropos/scss";

@keyframes slideIn {
    0% {
        transform: translateY(100%); /* Déplacer le texte vers le bas */
        opacity: 0; /* Masquer le texte */
    }
    100% {
        transform: translateY(0); /* Ramener le texte à sa position d'origine */
        opacity: 1; /* Afficher complètement le texte */
    }
}

@keyframes hover-card {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes card-appear {
    from {
        scale: 0;
        opacity: 0;
    }
    to {
        scale: 1;
        opacity: 1;
    }
}

#welcome {
    background-image: url("../../assets/img/accueil/background.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    height: fit-content;
    width: 100%;
    font-family: "Manrope";

    main {
        .section-1 {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 13vw;
            width: 45%;
            height: fit-content;
            height: calc(100vh - 100px);

            @media screen and (max-width: 768px) {
                width: 100%;
                margin-left: 0;
                padding: 0 5vw;
                text-align: center !important;
            }

            div {
                display: flex;
                align-items: center;
                flex-direction: column;
                align-items: flex-start;
                animation: slideIn 1s forwards;

                @media screen and (max-width: 768px) {
                    align-items: center;
                }

                h1 {
                    font-size: 3em;
                    color: $grisClair;
                    display: inline;
                    font-weight: bold;
                    letter-spacing: -1px;

                    em {
                        display: inline-flex;
                        flex-direction: column;
                        width: fit-content;

                        svg {
                            width: 215px;
                        }
                    }
                }

                p {
                    font-size: 1.5em;
                    color: $grisClair2;

                    @media screen and (max-width: 768px) {
                        font-size: 1em;
                    }
                }

                a {
                    background-color: $bleuFonce;
                    padding: 10px 20px;
                    border-radius: 6px;
                    color: white;
                    transition: 0.5s;
                    text-decoration: none;
                    margin-top: 20px;
                    font-weight: bold;

                    &:hover {
                        background-color: white;
                        color: $bleuFonce;
                    }
                }
            }
        }

        .section-2 {
            background-color: white;
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-wrap: wrap;
            gap: 3vw;
            padding: 15vh 5vw;

            @media screen and (max-width: 1268) {
                padding: 15vh 0;
            }

            .card {
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                position: relative;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                border-radius: 10px;
                width: 450px;
                height: 70vh;
                min-height: 15vh;
                padding: 3px;
                animation: card-appear linear;
                animation-timeline: scroll();
                animation-range-end: 170vh;

                @media screen and (max-width: 768px) {
                    height: 55vh;
                }

                &::before {
                    content: "";
                    position: absolute;
                    width: 0px;
                    height: 0px;
                    background-color: transparent;
                    z-index: 1;
                }

                &:hover::before {
                    animation: hover-card 10s infinite linear;
                    background-color: #117166;
                    transition: 0.3s ease-in;
                    width: 200px;
                    height: 180%;
                }

                .card-content {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    gap: 2vh;
                    padding: 25px;
                    transition: 0.3s ease-in;
                    border: 1px solid transparent;
                    background-color: white;
                    z-index: 2;
                    border-radius: 10px;

                    .card-header {
                        font-size: 2em;
                        font-weight: bold;
                        height: 150px;
                        text-align: center;
                        color: $grisClair;

                        @media screen and (max-width: 768px) {
                            font-size: 1.5em;
                        }
                    }

                    .card-body {
                        height: 300px;
                        text-align: justify;
                        color: $grisClair2;
                        font-size: 1.2em;

                        @media screen and (max-width: 768px) {
                            font-size: 1em;
                            height: 200px;
                        }
                    }
                }
            }

            .last-card {
                @media screen and (max-width: 1624px) {
                    display: none;
                }

                @media screen and (max-width: 1051px) {
                    display: flex;
                }
            }
        }

        .section-3 {
            height: 100vh;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: white;

            @media screen and (max-width: 768px) {
                height: 50vh;
            }

            .parallax-container {

                width: 60%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                animation: card-appear linear;
                animation-timeline: scroll();
                animation-range-end: 70vh;

                &:hover {
                    visibility: visible;
                }

                @media screen and (max-width: 1190px) {
                    width: 90%;        
                }

                .atropos-card {
                        position: relative;
                        aspect-ratio: 631 / 383;
                        width: 100%;
                        
                        .atropos-inner {
                            border-radius: 15px;
                            transition: 0.5s;
    
                            img {
                                position: absolute;
                                width: 130%;
                                height: 130%;
                                top: -15%;
                                left: -15%;
                                max-width: none;
                            }
    
                            h1 {
                                font-size: 2em;
                                color: $bleuFonce;
                                font-weight: bold;
                                position: absolute;
                                top: 10%;
                                left: 10%;
    
                                @media screen and (max-width: 768px) {
                                    font-size: 1.5em;
                                }
                            }
    
                            p {
                                font-size: 1.5em;
                                color: white;
                                text-shadow: 2px 2px 0px $bleuFonce;
                                
                                position: absolute;
                                top: 30%;
                                left: 13%;
    
                                @media screen and (max-width: 768px) {
                                    font-size: 1em;
                                }

                                b {
                                    font-weight: bold;
                                    background-color: $bleuFonce;
                                    color: white;
                                    padding: 1px;
                                }
                            }
                        }
                    }
            }

        }
    }
}
