@import "../../sass/variable.scss";

#navBar {
    position: sticky;
    width: 100%;
    top: 0;
    height: 100px;
    transition: 0.3s;
    z-index: 100;

    nav {
        background-color: transparent;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100px;
        //box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .2);

        img {
            width: 10vw;
            margin: 3vw;

            @media screen and (max-width: 910px) {
                width: 20vw;
            }
        }

        ul {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            list-style: none;
            width: 100%;
            padding-right: 5vw;
            gap: 5vw;
            font-family: "Manrope";
            font-size: 1rem;
            font-weight: bold;

            .link {
                color: $grisClair;
                font-weight: bold;
                text-decoration: none;
                transition: 0.5s;

                &:hover {
                    color: $bleuFonce;
                }
            }

            .button {
                background-color: $bleuFonce;
                padding: 10px 20px;
                border-radius: 6px;
                color: white;
                transition: 0.5s;

                &:hover {
                    background-color: white;
                    color: $bleuFonce;
                }
            }

            @media screen and (max-width: 910px) {
                display: none;
            }
        }
    }

    .btn-menu-mobile {
        display: none;

        @media screen and (max-width: 910px) {
            display: block;
            position: absolute;
            top: 8px;
            right: -55px;
            transform: translate(-100%, 60%);
            font-size: 1.8rem;
            cursor: pointer;
            color: $grisClair;
        }
    }
}

.welcome-menu-drawer {

    .closeButton {

        &:hover {
            background-color: transparent;
        }
    }

    .link {
        color: $grisClair;
        font-weight: bold;
        text-decoration: none;
        transition: 0.5s;

        &:hover {
            color: $bleuFonce;
        }
    }

    .button {
        background-color: $bleuFonce;
        padding: 10px 20px;
        border-radius: 6px;
        color: white;
        transition: 0.5s;
        width: 175px;
        text-align: center;

        &:hover {
            background-color: white;
            color: $bleuFonce;
        }
    }
}

.scrolled-nav {
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 7px;
}
