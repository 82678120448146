#viewer {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    word-wrap: normal !important;

    button {
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            width: 20px;
            height: 20px;
            padding: 0;
            margin: 0;
        }
    }
}
