@import "../../../../../../../../sass/variable.scss";

.mySitesSearchBar {
    width: 70vw;
    height: 5vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 0px 15px $gris;
    border-radius: 15px;

    @media screen and (max-width: 600px) {
        width: 100%;
    }

    input {
        width: 100%;
        height: 100%;
        outline: none;
    }

    span {
        width: 10%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 60px;

        svg {
            width: 100%;
            height: 40%;
            background-color: #fff;
            border-radius: 15px 0 0 15px;
        }
    }

    

    button {
        width: 10%;
        height: 100%;
        background-color: #fff;
        border-radius: 0 15px 15px 0;
        max-width: 60px;

        svg {
            width: 100%;
            height: 50%;
        }
    }


}