@import "../../sass/variable.scss";

.header {

    width: 100%;
    height: 75px;
    background-color: $vertFonce;
    position: fixed;
    top: 0;
    padding-left: 75px;
    z-index: 100;

    @media screen and (max-width: 910px) {
        padding-left: 0;
        
    }

    h1 {
        margin-left: auto;
        margin-right: auto;
        color: white;
        height: 100%;
        width: fit-content;
        font-weight: bold;
        font-size: 3em;

        @media screen and (max-width: 910px){
            font-size: 1.5em;
        }

        @media screen and (max-width: 500px){
            display: none;
            
        }
    }
}