@import "../../.././../../../../../sass/variable.scss";

#alertDeleteManualMesure {

    header {
        background-color: transparent;
    }

    span {
        display: flex;
    }
}

.chakra-modal__header {
    background-color: transparent;
}
