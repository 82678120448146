.ma-carte {
    width: calc(100vw - 90px);
    height: 100%;
    margin-left: 75px;
    margin-top: 75px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 910px) {
        margin-left: 0;
        width: 100%;
    }

    .popup-container {

        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: center;
        align-items: center;

        .voir-site {
            color: white;
            background-color: #225522;
            padding: 5px 15px;
            border-radius: 5px;
            font-family: "Manrope", sans-serif;
            transition: all 0.3s ease-in-out;
    
            &:hover {
                background-color: white;
                color: #225522;
            }
        }
    }
}
