@import "../../../../sass/variable.scss";

.mySitesPage {
    display: flex;
    flex-direction: row;
    height: 100vh;

    main {
        display: flex;
        flex-direction: column;
        gap: 10vh;
        align-items: center;
        justify-content: flex-start;
        margin-left: auto;
        margin-right: auto;
        margin-top: 110px;
        padding-left: 75px;
        width: 95vw;
        padding-bottom: 3vh;

        @media screen and (max-width: 910px) {
            width: 100%;
            padding-left: 0;
            
        }

        @media screen and (max-width: 600px) {
            padding: 20px;
            
        }
    }

    em {
        background-color: $vertClair;
        color: #fff;
        font-style: normal;
    }
}