.dashboard {

    display: flex;
    justify-content: center;
    align-items: center;

    #dashboard {
        width: 100%;
        height: 100%;

        .highcharts-dashboards,
    .highcharts-dashboards-wrapper {
        background-color: #f2f9fd00;
        width: 100%;
        height: 100%;

        .highcharts-dashboards-row {
            display: flex;
            flex-wrap: wrap;

            @media screen and (max-width: 1300px) {
                flex-direction: column;
                
            }

            .highcharts-dashboards-cell {
                width: 100%;
            }
        }
    }
    }

    
}
