#chart-batiment {
  width: 100%;
  height: 100%;

  & > div {
    height: 100%;
  }

  .highcharts-plot-band {
    fill: #B0C4F0 !important;
    fill-opacity: 0.5 !important;
  }

  .highcharts-plot-band-label {
    font-size: 12px !important;
    font-weight: bold !important;
  }
}