@import "../../sass/variable.scss";

.not-found {
    height: 100vh;

    .logo {
        width: 190px;
        padding-left: 50px;
        padding-top: 50px;

        @media screen and (max-width: 798px) {
            padding-left: 0;
            padding-top: 25px;
            margin-right: auto;
            margin-left: auto;
            width: 150px;
        }
    }

    .content {
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc(100% - 120px);

        @media screen and (max-width: 1100px) {
            flex-direction: column-reverse;
            height: fit-content;
        }

        section {
            height: 100%;
            width: 35vw;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 35px;

            @media screen and (max-width: 1100px) {
                margin-top: 30px;
                height: fit-content;
            }

            @media screen and (max-width: 798px) {
                width: 80vw;              
            }

            h1 {
                text-align: left;
                font-family: "Manrope", sans-serif;
                font-size: 5rem;
                font-weight: bold;
                font-style: oblique;
                color: $bleuFonce;
                width: 100%;

                @media screen and (max-width: 1100px){
                    font-size: 3.5rem;
                    text-align: center;
                }
            }

            h2 {
                text-align: start;
                font-family: "Manrope", sans-serif;
                font-size: 2rem;
                font-weight: 300;
                color: $grisClair;

                @media screen and (max-width: 1100px){
                    font-size: 1.5rem;
                    text-align: center;
                }
            }

            p {
                text-align: left;
                width: 100%;
                font-family: 'Manrope', sans-serif;
                font-size: 1.5rem;
                font-weight: 500;
                color: $grisClair;

                @media screen and (max-width: 1100px){
                    text-align: center;
                }
            }

            ul {
                list-style: none;
                text-align: start;
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 10px;

                @media screen and (max-width: 1100px){
                    text-align: center;
                    margin-bottom: 15vh;
                }

                a {
                    color: $bleuFonce;
                    font-family: 'Manrope', sans-serif;
                    font-size: 1.1rem;
                    font-weight: 500;
                    text-decoration: none;
                    transition: color 0.3s;

                    &:hover {
                        color: $bleuClair;
                    }
                
                }
            }
        }

        img {
            pointer-events: none;
            width: 35vw;

            @media screen and (max-width: 1100px){
                width: 40vw;
            }

            @media screen and (max-width: 798px){
                width: 80vw;
                margin-top: 5vh;
            }
        }
    }

    footer {
        height: 20px;
        text-align: center;

        a {
            color: $grisClair2;
        }
    }
}
