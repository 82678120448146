@import "./variable.scss";
@import url("https://code.highcharts.com/css/highcharts.css");
@import url("https://code.highcharts.com/dashboards/css/dashboards.css");
@import url("https://code.highcharts.com/dashboards/css/datagrid.css");

body {
    margin: 0;
    overflow-y: scroll;
    font-family: 'Manrope', sans-serif;
    
    #root {
        height: calc(100vh - 75px);
    }
}