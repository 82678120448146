@import "../../../../../../../../../../sass/variable.scss";

.data-display {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
    padding: 3px;
    width: 100%;
    height: fit-content;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

    .data-display-value {
        font-size: 1.5rem;
        font-weight: bold;
    }

    h1 {
        font-size: 1.2rem;
        text-decoration: underline;
        color: $vertFonce;
    }

    .data-display-date {
        font-size: 0.8rem;
        color: $grisClair2;
    }
}