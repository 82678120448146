@import "../../../../../../sass/variable.scss"; 

.chartCalibration {
    width: 100%;
    height: 100%;

    .addSection {
        display: flex;
        align-items: center;
        padding: 3vh 0;

        @media screen and (max-width: 700px) {
            flex-direction: column;
            gap: 10px;

            a {
                left: 0;
                margin-top: 10px;
            }
            
        }

        a {
            left: 2vw;
            position: relative;

            button {
                background-color: $vertFonce;
                color: $blanc;

                &:hover {
                    background-color: $blanc;
                    color: $vertFonce;
                    transition: 0.3s ease-in;
                }
            }
        }

        div {
            margin-left: auto;
            margin-right: auto;
        }

        & + div {
            height: 80vh;
        }
    }

    .no-data {
        text-align: center;
        margin-top: 10vh;
    }
}
