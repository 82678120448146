@import "../../../../../../sass/variable.scss";

.dashboard-batiment-chart-panel {
    border-radius: 6px;
    box-shadow: 0 0 10px 0 rgba(34, 85, 34, 0.1);
    position: relative;

    .select {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        height: fit-content;
        gap: 20px;
        margin-top: 4px;
    }

    .chart {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        #chart-dashboard-batiment {
            width: 100%;
            height: 100%;

            &>div {
                width: 100%;
                height: 87%;
            }

            #time-ref {
                position: absolute;
                top: 15px;
                left: 15px;
                color: $grisClair;
                font-size: 0.8em;
            }

            h1 {
                text-align: center;
                margin-top: 50px;
            }
        }
    }
}
